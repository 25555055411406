import React, { useReducer } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"

import InputText from "./inputText"
import Textarea from "./textarea"
import submitHubSpotForm from "../services/submitHubSpotForm"
import styles from "./contactForm.module.scss"

const formId = "1d688c1d-b27d-4874-8621-3f3a381f42dd"

const ContactForm = () => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "input":
          return {
            ...state,
            fields: {
              ...state.fields,
              [action.fieldName]: action.value,
            },
          }
        case "submitted":
          return {
            ...state,
            submitting: true,
            success: false,
            errorMessage: null,
          }
        case "succeeded":
          return {
            ...state,
            submitting: false,
            success: true,
            errorMessage: null,
            data: action.data,
          }
        case "failed":
          return {
            ...state,
            submitting: false,
            errorMessage: action.message || "Error. Try again later.",
            success: false,
          }
        default:
          return state
      }
    },
    {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        message: "",
      },
      submitting: false,
      success: false,
      errorMessage: null,
      data: null,
    }
  )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch({ type: "submitted" })

    submitHubSpotForm(formId, [
      {
        name: "firstname",
        value: state.fields.firstname,
      },
      {
        name: "lastname",
        value: state.fields.lastname,
      },
      {
        name: "email",
        value: state.fields.email,
      },
      {
        name: "message",
        value: state.fields.message,
      },
    ])
      .then(data => {
        // Track custom Google Analytics events
        trackCustomEvent({
          category: "Contact Form",
          action: "Form Submitted",
          hitCallback: () => {
            // Do we need this if we're redirecting after submission?
            dispatch({ type: "succeeded", data })
            navigate("/contact/success")
          },
        })
      })
      .catch(error => {
        const errorType = error.response.data.errors[0].errorType
        console.log(error)
        if (errorType === "INVALID_EMAIL") {
          dispatch({
            type: "failed",
            message:
              "That doesn’t look quite right. We'll need a valid email address.",
          })
        } else {
          dispatch({
            type: "failed",
            message:
              "Something went wrong. Sorry about that. Try again later or send us a message hello@zeusjones.com",
          })
        }
      })
  }

  return (
    <form
      method="post"
      action="/contact/success"
      name="Contact"
      onSubmit={handleSubmit}
    >
      <h2 className="screen-reader-only">Contact Form</h2>
      <p className="body-small mb-2">All fields are required.</p>
      <div className="mb-4">
        <InputText
          label="First Name"
          name="firstname"
          id="contact-form-first-name"
          type="text"
          placeholder=""
          required={true}
          block={true}
          background="dark-green"
          onChange={value => {
            dispatch({ type: "input", fieldName: "firstname", value })
          }}
          value={state.fields.firstname}
        />
      </div>
      <div className="mb-4">
        <InputText
          label="Last Name"
          name="lastname"
          id="contact-form-last-name"
          type="text"
          placeholder=""
          required={true}
          block={true}
          background="dark-green"
          onChange={value => {
            dispatch({ type: "input", fieldName: "lastname", value })
          }}
          value={state.fields.lastname}
        />
      </div>
      <div className="mb-4">
        <InputText
          label="Email address"
          name="email"
          id="contact-form-email"
          type="email"
          placeholder=""
          required={true}
          block={true}
          background="dark-green"
          onChange={value => {
            dispatch({ type: "input", fieldName: "email", value })
          }}
          value={state.fields.email}
        />
      </div>
      <div className="mb-7">
        <Textarea
          label="Your message"
          name="message"
          id="contact-form-message"
          placeholder=""
          required={true}
          block={true}
          onChange={value => {
            dispatch({ type: "input", fieldName: "message", value })
          }}
          value={state.fields.message}
        />
      </div>
      <button className="button" type="submit">
        Submit
      </button>
      <div className={styles.formResponse}>
        <div className="error">{state.errorMessage}</div>
      </div>
    </form>
  )
}

export default ContactForm
